<template>
	<div class="pager" :style="style">
		<span class="inside" :style="styleInside" ref="inside" />
	</div>
</template>

<script>
import { Quad, gsap } from 'gsap/gsap-core'

export default {
	props: {
		rounded: {
			type: Boolean,
			default: true
		},
		width: {
			type: Number,
			default: 72
		},
		height: {
			type: Number,
			default: 4
		},
		bgColor: {
			type: String,
			default: '#C9E8FF'
		},
		bgColorInside: {
			type: String,
			default: '#0097FE'
		},
		itemCount: {
			type: Number,
			default: 3
		}
	},

	computed: {
		style () {
			return `width:${this.width}px; height:${this.height}px; background:${this.bgColor};`
		},
		styleInside () {
			return `width:${this.width / this.itemCount}px; height:${this.height}px; ; background:${this.bgColorInside};`
		}
	},
	mounted () {
		this.show()
	},
	methods: {
		show () {
			gsap.from(this.$el, { opacity: 0, y: 30, delay: 0.6, duration: 0.4, ease: Quad.easeOut })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, y: -30, duration: 0.4, ease: Quad.easeIn })
		},
		select (id) {
			const percent = id / (this.itemCount - 1)
			const x = percent * (this.width - this.width / this.itemCount)
			gsap.to(this.$refs.inside, { x: x, duration: 0.4, ease: Quad.easeOut })
		}
	}
}
</script>

<style lang="stylus" scoped>
	.pager {
		overflow: hidden;
		position: relative;
		border-radius: 5px;
		margin auto
	}

	.inside {
		position: absolute;
		display: inline-block;
		border-radius: 5px;
		top:0;
		left:0;
	}
</style>
