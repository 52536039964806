<template>
	<div
		class="slider-basic"
		@touchmove="onMouseMove"
		@touchup="onMouseUp"
		@touchstart="onMouseDown"
		@touchend="onMouseUp"
		@mouseup="onMouseUp"
		@mousedown="onMouseDown"
		@mousemove="onMouseMove"
		@mouseleave="onMouseLeave"
	>
		<div class="top">
			<slot name="top" />
		</div>
		<slot />
		<div class="bottom">
			<slot name="bottom" />
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap/all'

export default {
	name: 'SliderBasic',

	props: {
		count: {
			type: Number,
			default: 2
		}
	},

	emits: ['change'],

	data () {
		return {
			prevID: -1,
			currentID: -1,
			direction: 'none', // none / left / right
			swipeStartTime: 0,
			swipeStartX: 0,
			lastMouseX: 0
		}
	},

	mounted () {
		this.select(0)
	},

	methods: {
		onMouseUp (e) {
			const xDiff = this.lastMouseX - this.swipeStartX
			if (xDiff < 0) {
				this.next()
			} else if (xDiff > 0) {
				this.prev()
			}
		},

		onMouseDown (e) {
			this.swipeStartTime = Date.now()
			this.startX = gsap.getProperty(this.$el, 'x')
			this.swipeStartX = e.touches ? e.touches[0].clientX : e.clientX
			this.lastMouseX = this.swipeStartX
		},

		onMouseMove (e) {
			this.lastMouseX = e.touches ? e.touches[0].clientX : e.clientX
			gsap.set(this.$el, { x: this.startX - this.swipeStartX + this.lastMouseX })
		},

		// ----------------------------------------------------------------------------- NEXT / PREV / SELECT
		next () {
			if (this.currentID == this.count - 1) {
				this.$emit('change', {
					currentId: this.currentID,
					prevId: this.prevID,
					direction: 'left'
				})
				return
			}
			this.select(Math.min(this.count - 1, this.currentID + 1))
		},
		prev () {
			if (this.currentID == 0) {
				this.$emit('change', {
					currentId: this.currentID,
					prevId: this.prevID,
					direction: 'right'
				})
				return
			}
			this.select(Math.max(0, this.currentID - 1))
		},

		// select a specific index
		select (id, forceRefresh = false) {
			if (id == this.currentID) {
				if (!forceRefresh) {
					return
				}
			}
			this.prevID = this.currentID
			this.currentID = id
			this.direction = this.currentID > this.prevID ? 'left' : 'right'
			this.$emit('change', {
				currentId: this.currentID,
				prevId: this.prevID,
				direction: this.direction
			})
		}
	}
}
</script>

<style lang="stylus" scoped>
	.slider-basic {
		position: relative;
		user-select: none;
	}
</style>
