<template>
	<div class="cloud" :class="{reversed:reversed}" :style="{top:offsetTop+'px'}" />
</template>

<script>
import { gsap, Quad } from 'gsap/all'

export default {
	name: 'CloudBackground',
	props: {
		reversed: {
			type: Boolean,
			default: false
		},
		offsetTop: {
			type: Number,
			default: 0
		}
	},
	mounted () {
		gsap.set(this.$el, { y: 100, opacity: 0 })
		this.show()
	},
	methods: {
		show () {
			gsap.to(this.$el, { opacity: 1, y: 42, ease: Quad.easeOut, duration: 1.6 })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, y: 0, ease: Quad.easeOut, duration: 0.7 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.cloud
	will-change transform, opacity
	background-image: url('/img/views/home/cloud.png')
	background-size: contain;
	background-repeat no-repeat
	position: absolute;
	height 80%
	width 100%
	overflow hidden
	z-index -1
	top: 0px
	left: 0px
	&.reversed
		background-image: url('/img/views/booking/cloud.png')
</style>
