<template>
	<div class="booking">
		<BackButton ref="back" />
		<TextKR kr="선생님" size="small" ref="title">
			Book a teacher
		</TextKR>
		<ProfileButton ref="profile" />
		<CloudBackground ref="cloud" :reversed="true" :offset-top="120" />

		<div class="headlines">
			<div class="headline" ref="line1">
				Dynamic,
			</div>
			<div class="headline" ref="line2">
				Fun, Efficient
			</div>
		</div>
		<div class="intro" ref="line3">
			Our Korean classes are designed<br>to help you progress faster in<br>Korean!
		</div>

		<SliderBasic
			:count="3"
			@change="onSliderChange"
			ref="slider"
		>
			<SquareButton ref="button1"
				title="Private Lesson"
				subtitle="Individual Lesson"
				illustration="img/views/booking/happy-kimbapp.png"
				desc="1 on 1"
				@click="onSelect(0)"
			/>
			<SquareButton ref="button2"
				title="Class Lesson"
				subtitle="Study with a small group!"
				illustration="img/views/booking/happy-kimbapps.png"
				desc="make new friends! max: 6peoples"
				@click="onSelect(1)"
			/>
			<PhilosophyButton ref="button3"
				@click="onSelect(2)"
			/>
		</SliderBasic>
		<PagerBar ref="pager" />
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'
import ProfileButton from '@/components/ProfileButton'
import SquareButton from '@/components/booking/SquareButton.vue'
import SliderBasic from '@/components/sliders/SliderBasic.vue'
import PagerBar from '@/components/sliders/PagerBar.vue'
import PhilosophyButton from '@/components/booking/PhilosophyButton.vue'
import CloudBackground from '@/components/CloudBackground.vue'

export default {
	name: 'Booking',
	components: {
		ProfileButton,
		SliderBasic,
		PagerBar,
		SquareButton,
		PhilosophyButton,
		CloudBackground
	},
	mounted () {
		// position elements in absolute relative to the screen-size
		gsap.set(this.$refs.button2.$el, { x: window.innerWidth - 55 })
		gsap.set(this.$refs.button3.$el, { x: (window.innerWidth - 55) * 2 })
		gsap.set(this.$refs.slider.$el, { x: 0, ease: Quad.easeOut })
	},
	methods: {
		transitionIn () {
			gsap.from(this.$refs.title, { opacity: 0, y: 30, ease: Quad.easeOut })
			gsap.from(this.$refs.line1, { opacity: 0, x: -30, ease: Quad.easeOut })
			gsap.from(this.$refs.line2, { opacity: 0, x: -30, ease: Quad.easeOut, delay: 0.1 })
			gsap.from(this.$refs.line3, { opacity: 0, x: -30, ease: Quad.easeOut, delay: 0.2 })
			gsap.from(this.$refs.button1.$el, { opacity: 0, y: 50, ease: Quad.easeOut, delay: 0.4 })
			gsap.from(this.$refs.button2.$el, { opacity: 0, y: 50, ease: Quad.easeOut, delay: 0.5 })
			gsap.from(this.$refs.button3.$el, { opacity: 0, y: 50, ease: Quad.easeOut, delay: 0.6 })
		},
		transitionOut (cb) {
			this.$refs.back.hide()
			this.$refs.title.hide()
			this.$refs.profile.hide()
			this.$refs.cloud.hide()
			this.$refs.pager.hide()
			gsap.to(this.$refs.title, { opacity: 0, y: -30, ease: Quad.easeOut })
			gsap.to(this.$refs.line1, { opacity: 0, x: 30, ease: Quad.easeOut })
			gsap.to(this.$refs.line2, { opacity: 0, x: 30, ease: Quad.easeOut, delay: 0.1 })
			gsap.to(this.$refs.line3, { opacity: 0, x: 30, ease: Quad.easeOut, delay: 0.2 })
			gsap.to(this.$refs.button1.$el, { opacity: 0, y: -50, ease: Quad.easeOut, delay: 0.1 })
			gsap.to(this.$refs.button2.$el, { opacity: 0, y: -50, ease: Quad.easeOut, delay: 0.2 })
			gsap.to(this.$refs.button3.$el, { opacity: 0, y: -50, ease: Quad.easeOut, delay: 0.3, onComplete: cb })
		},
		onSliderChange (values) {
			const x = -(window.innerWidth - 55) * values.currentId
			gsap.to(this.$refs.slider.$el, { x: x, ease: Quad.easeOut })
			this.$refs.pager.select(values.currentId)
		},
		onSelect (value) {
			if (this.$refs.slider.selected == value) {

			} else {
				this.$refs.slider.select(value)
			}
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut(next)
	}
}
</script>

<style lang="stylus" scoped>
.booking
	color #000
	margin-left 35px
	.slider-basic
		height calc(100vw - 30px)
		margin-bottom 15px
	.headlines
		margin-top 20px
	.headline
		text-align left
		font-family: NanumGothic;
		font-size: 35px;
		font-style: normal;
		font-weight: 700;
		line-height: 39px;
		letter-spacing: -0.02em;
	.intro
		font-family: HK Grotesk Pro;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: -0.03em;
		text-align: left;
		margin-bottom: 50px
		margin-top 20px
		color rgba(130, 130, 130, 1)

	.SquareButton,.PhilosophyButton
		position absolute
		top 0
		left 0

</style>
