<template>
	<div class="SquareButton" :class="{darkMode:darkMode}">
		<div class="title" v-html="title" />
		<img v-if="illustration" :src="illustration" class="illustration">
		<div class="subtitle" v-html="subtitle" />
		<div class="desc" v-html="desc" />
	</div>
</template>

<script>

export default {
	name: 'SquareButton',
	props: {
		title: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		illustration: {
			type: String,
			default: ''
		},
		desc: {
			type: String,
			default: ''
		},
		darkMode: {
			type: Boolean,
			default: false
		},
		background: {
			type: String,
			default: ''
		}
	},
	mounted () {
		if (this.background != '') {
			this.$el.style.background = this.background
		}
	}
}
</script>

<style lang="stylus" scoped>
.SquareButton
	padding 20px
	background: linear-gradient(180deg, rgba(0, 151, 254, 0.5) 0%, #0097FE 100%);
	box-shadow: 0px 41.7776px 33.4221px rgba(8, 181, 255, 0.0503198), 0px 22.3363px 17.869px rgba(8, 181, 255, 0.0417275), 0px 12.5216px 10.0172px rgba(8, 181, 255, 0.035), 0px 6.6501px 5.32008px rgba(8, 181, 255, 0.0282725), 0px 2.76726px 2.21381px rgba(8, 181, 255, 0.0196802);
	border-radius: 20px;
	user-select none
	margin-bottom 10px
	color #fff
	margin-right 35px
	height calc(100vw - 70px)
	will-change transform, opacity

	.illustration
		margin-top 30px
		width calc(100% - 50px)
		height auto
	.title
		font-family: NanumGothic;
		font-size: 20px;
		font-style: normal;
		font-weight: 800;
		line-height: 20px;
		letter-spacing: -0.02em;
		text-align: left;
		text-transform uppercase
		margin-bottom 20px
	.subtitle
		font-family: NanumGothic;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 15px;
		letter-spacing: -0.02em;
		text-align: left;
		margin-top 30px
	.desc
		margin-top 10px
		font-family: 'HK Grotesk Pro';
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 13px;
		letter-spacing: -0.03em;
		text-align: left;
	h4
		margin-top 10px
		color #333
		font-size 12px
		padding 0
		margin-bottom 0
	&.darkMode
		color #000
		.desc
			color #828282

</style>
